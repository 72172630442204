import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Flex,
  Stack,
  VStack,
  HStack,
  Box,
  Button,
  Icon,
  Spacer,
  Text,
  Textarea,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useDisclosure,
  Circle,
  Divider,
  Skeleton,
  useToast,
  Input
} from '@chakra-ui/react'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy
} from '@dnd-kit/sortable'
import { ArrowBackIcon, CloseIcon, CheckIcon } from '@chakra-ui/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import RenderImageVisitsContainer from '../../components/Shared/RenderImageVisitsContainer'

import { FlagIcon } from '../../components/Shared/Icons'
import UploadImage from '../Shared/UploadImage'
import ConfirmModal from '../Shared/ConfirmModal'
import { useMutateReport, useReport } from '../../hooks/visitReport'
import { getUrlImage } from '../../store/slices/fileUpload'
import CustomToast from '../Shared/CustomToast'
import { TextAreaInput } from '../Shared/inputs'

const validationSchema = Yup.object().shape({
  problematicAreas: Yup.string().max(
    1000,
    'El máximo de caracteres permitido es de 1000'
  ),
  generalObservations: Yup.string().max(
    5000,
    'El máximo de caracteres permitido es de 5000'
  )
})

// Reporte de visita
const VisitReport = () => {
  const toast = useToast()
  const { reportId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { data: report, isLoading: loadingGet } = useReport(reportId)
  const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [formDataChecks, setFormDataChecks] = useState([])
  const [fisical, setFisical] = useState(false)
  const [hygene, setHygene] = useState(false)
  const [seal, setSeal] = useState(false)
  const [storage, setStorage] = useState(false)
  const [trashCan, setTrashCan] = useState(false)
  const [food, setFood] = useState(false)
  const [branches, setBranches] = useState(false)
  const [vegetation, setVegetation] = useState(false)
  const [container, setContainer] = useState(false)
  const [sewer, setSewer] = useState(false)
  const [perimeter, setPerimeter] = useState(false)
  const [trash, setTrash] = useState(false)
  const [images, setImages] = useState([])
  const { client, branch } = useSelector((state) => state.visits)
  const { mutate, isLoading: loadingUpdate, reset } = useMutateReport('UPDATE')
  const {
    isOpen: openConfirm,
    onOpen: onOpenConfirm,
    onClose: closeConfirm
  } = useDisclosure()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (!over) {
      return
    }

    if (active.id !== over.id) {
      setImages((items) => {
        const oldIndex = items.findIndex((img) => img.url === active.id)
        const newIndex = items.findIndex((img) => img.url === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues: {
      problematicAreas: ''
    },
    onSubmit: (values) => {
      const formData = {
        id: report.id,
        active_pests: formDataChecks,
        fisical: fisical,
        hygene: hygene,
        seal: seal,
        storage: storage,
        trash_can: trashCan,
        food: food,
        branches: branches,
        vegetation: vegetation,
        container: container,
        sewer: sewer,
        perimeter: perimeter,
        trash: trash,
        problematic_areas: values.problematicAreas,
        general_observations: values.generalObservations,
        start_time: values.start_time,
        end_time: values.end_time,
        images: images
      }
      mutate(formData, {
        onSuccess: () => {
          reset()
          formik.resetForm()
          setConfirm(true)
        },
        onError: (err) => {
          reset()
          console.log(err)
          if (err.request.response.includes('ERROR_SAVING_VISIT_REPORT')) {
            toast({
              title: `Error al guardar datos`,
              status: 'error',
              isClosable: true
            })
          } else if (err.request.response.includes('ERROR_GENERATING_PDF')) {
            toast({
              title: `Error al crear PDF, datos guardados sin problemas`,
              status: 'error',
              isClosable: true
            })
          } else {
            toast({
              title: `Error indefinido`,
              status: 'error',
              isClosable: true
            })
          }
        }
      })
    }
  })

  // Guarda en el estado al cargar los datos
  useEffect(() => {
    if (report) {
      setFormDataChecks(report.active_pests)
      setFisical(report.fisical)
      setHygene(report.hygene)
      setSeal(report.seal)
      setStorage(report.storage)
      setTrashCan(report.trash_can)
      setFood(report.food)
      setBranches(report.branches)
      setVegetation(report.vegetation)
      setContainer(report.container)
      setSewer(report.sewer)
      setPerimeter(report.perimeter)
      setTrash(report.trash)
      setImages(report.images)
      formik.setValues({
        problematicAreas: report?.problematic_areas || '',
        generalObservations: report?.general_observations || '',
        start_time: report?.start_time || '',
        end_time: report?.end_time || ''
      })
    }
  }, [report, loadingGet])

  const handleBack = () => {
    history.push(`/visitas/${report.id_visit}`)
  }

  //Maneja el estado de los checkboxs
  const handleChangeCheck = (event) => {
    const { name } = event.target
    //Agrega un elemento a la lista de checks selecionados
    let tempCheck = [...formDataChecks, name]
    //Si el check ya se encuentra lo elimina
    if (formDataChecks.includes(name)) {
      tempCheck = tempCheck.filter((per) => per !== name)
    }
    setFormDataChecks(tempCheck)
  }

  const handleClickConfirm = () => {
    onOpenConfirm()
  }

  //Maneja la subida de imagen a un elemento de la lista
  const handleImage = (imagesUrl) => {
    const newArray = [...images]
    imagesUrl.forEach((item) => {
      newArray.push({
        url: item.url,
        filename: item.filename,
        observation: ''
      })
    })
    setImages(newArray)
  }

  //logica de subida de imagen

  const imgUpload = async (formImage) => {
    try {
      setIsLoadingUploadFile(true)
      let responses = []
      for (let i = 0; i < formImage.length; i++) {
        const item = formImage[i]
        const formData = new FormData()
        formData.append('file', item)
        const response = await dispatch(getUrlImage(formData))
        responses.push(response)
      }
      if (responses.length) {
        handleImage(responses)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingUploadFile(false)
    }
  }

  const handleRemoveImage = (position) => {
    const filteredImages = images.filter((img, idx) => idx !== position)
    setImages(filteredImages)
  }

  const handleChangeImages = (payload, position) => {
    const updatedValues = images.map((img, idx) => {
      if (idx === position) {
        return {
          ...img,
          [payload.name]: payload.value
        }
      }
      return img
    })
    setImages(updatedValues)
  }

  useEffect(() => {
    if (isLoadingUploadFile) {
      toast({
        duration: 9000 * 9000,
        isClosable: true,
        position: 'bottom-right',
        render: ({ onClose }) => (
          <CustomToast
            text={'Cargando Imagen...'}
            status={'info'}
            onClose={onClose}
          />
        )
      })
      return
    }
    toast.closeAll()
  }, [isLoadingUploadFile])

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex spacing={10} align="right" pt="15px">
        <Button
          onClick={handleBack}
          bg="#FFFFFF"
          color="#666587"
          border="1px"
          borderStyle="solid"
          borderRadius="lg"
          borderColor="#F2F1FB"
          leftIcon={<ArrowBackIcon />}
        >
          Documentos de visita
        </Button>
      </Flex>
      <Box w="100%" bg="#F2F1FB80" pb={5}>
        <Flex spacing={10} align="left" pt="15px">
          <Stack spacing={2} pl={30}>
            <Text fontSize="10px" color="#36355F">
              Cliente
            </Text>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              {client}
            </Text>
          </Stack>
          <Stack spacing={2} pl={30}>
            <Text fontSize="10px" color="#36355F">
              Sucursal
            </Text>
            <Text fontSize="17px" fontWeight="bold" color="#36355F">
              {branch}
            </Text>
          </Stack>
        </Flex>
      </Box>
      {report ? (
        <>
          <Box w="100%" bg="#F2F1FB" pb={5}>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="15px" color="#36355F">
                  <Icon mr="4" fontSize="22" as={FlagIcon} color="#36355F" />
                  Reporte de visita
                </Text>
              </Stack>
              <Spacer />
              <Stack spacing={2} pr={30}>
                <Text fontSize="10px" color="#36355F">
                  Serial {report.id}
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Box w="100%" bg="#D1F1F5" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Plagas activas
                </Text>
              </Stack>
            </Flex>
          </Box>
          <VStack spacing={8} py={12} px={6}>
            <HStack justify={'space-between'} w={['100%', '15%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Roedores
              </Text>
              <Checkbox
                size="md"
                colorScheme="blue"
                name="Rodents"
                isChecked={formDataChecks.includes('Rodents')}
                onChange={handleChangeCheck}
                borderColor="grey"
              ></Checkbox>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '15%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Insectos voladores
              </Text>
              <Checkbox
                size="md"
                colorScheme="blue"
                name="Flyers"
                isChecked={formDataChecks.includes('Flyers')}
                onChange={handleChangeCheck}
                borderColor="grey"
              ></Checkbox>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '15%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Insectos rastreros
              </Text>
              <Checkbox
                size="md"
                colorScheme="blue"
                name="Crawling"
                isChecked={formDataChecks.includes('Crawling')}
                onChange={handleChangeCheck}
                borderColor="grey"
              ></Checkbox>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '15%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Cucarachas
              </Text>
              <Checkbox
                size="md"
                colorScheme="blue"
                name="Cockroach"
                isChecked={formDataChecks.includes('Cockroach')}
                onChange={handleChangeCheck}
                borderColor="grey"
              ></Checkbox>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '15%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Otro
              </Text>
              <Checkbox
                size="md"
                colorScheme="blue"
                name="Other"
                isChecked={formDataChecks.includes('Other')}
                onChange={handleChangeCheck}
                borderColor="grey"
              ></Checkbox>
            </HStack>
          </VStack>
          <Box w="100%" bg="#F2F1FB" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Condiciones que permiten el ingreso/refugio/establecimiento de
                  plagas en la instalación (MIP)
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Box w="100%" bg="#F2F1FB" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Condiciones interiores
                </Text>
              </Stack>
            </Flex>
          </Box>
          <VStack spacing={8} py={12} px={6}>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Puertas y ventanas con barreras físicas que eviten ingreso
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={fisical ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setFisical(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={fisical ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setFisical(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Adecuada higiene en interior
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={hygene ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setHygene(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={hygene ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setHygene(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Junturas y orificios sellados
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={seal ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setSeal(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={seal ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setSeal(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Almacenamiento adecuado de materiales y/o insumos
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={storage ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setStorage(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={storage ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setStorage(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Basurero con tapa y cerrado
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={trashCan ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setTrashCan(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={trashCan ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setTrashCan(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Alimentos almacenados correctamente
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={food ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setFood(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={food ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setFood(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
          </VStack>
          <Box w="100%" bg="#F2F1FB" pb={5} borderRadius="20px">
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  Condiciones exteriores
                </Text>
              </Stack>
            </Flex>
          </Box>
          <VStack spacing={8} py={12} px={6}>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Ramificaciones tocando la estructura
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={branches ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setBranches(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={branches ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setBranches(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Presenta vegetación espontánea
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={vegetation ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setVegetation(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={vegetation ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setVegetation(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Contenedor de basura con tapa y cerrado
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={container ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setContainer(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={container ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setContainer(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Tapas de alcantarilla en buen estado
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={sewer ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setSewer(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={sewer ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setSewer(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Perímetro estructural libre de materiales en desuso
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={perimeter ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setPerimeter(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={perimeter ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setPerimeter(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
            <HStack justify={'space-between'} w={['100%', '20%']}>
              <Text fontSize="14px" fontWeight="bold" color="#36355F">
                Presencia de basura y/o materiales en desuso
              </Text>
              <HStack justify={'space-between'} w={['30%', '20%']}>
                <Circle
                  size="30px"
                  bg={trash ? '#E6E6EF' : 'red'}
                  color="white"
                  as="button"
                  onClick={() => setTrash(false)}
                >
                  <CloseIcon />
                </Circle>
                <Circle
                  size="30px"
                  bg={trash ? 'green' : '#E6E6EF'}
                  color="white"
                  as="button"
                  onClick={() => setTrash(true)}
                >
                  <CheckIcon />
                </Circle>
              </HStack>
            </HStack>
          </VStack>
          <Divider orientation="horizontal" size={'xl'} bg="#E6E6EF" />
          <FormControl
            id="start_time"
            isInvalid={
              formik.touched.start_time && Boolean(formik.errors.start_time)
            }
          >
            <FormLabel>Hora de entrada</FormLabel>
            <Input
              name="start_time"
              value={formik.values.start_time}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Hora entrada"
              size="md"
              type="time"
            />
          </FormControl>
          <FormControl
            id="end_time"
            isInvalid={
              formik.touched.end_time && Boolean(formik.errors.end_time)
            }
          >
            <FormLabel>Hora de salida</FormLabel>
            <Input
              name="end_time"
              value={formik.values.end_time}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Hora salida"
              size="md"
              type="time"
            />
          </FormControl>
          <FormControl
            id="problematicAreas"
            isInvalid={
              formik.touched.problematicAreas &&
              Boolean(formik.errors.problematicAreas)
            }
          >
            <FormLabel>Áreas problemáticas</FormLabel>
            <Textarea
              name="problematicAreas"
              value={formik.values.problematicAreas}
              onChange={formik.handleChange}
              placeholder="Áreas problemáticas"
              h="20%"
            />
            <FormErrorMessage>
              {formik.touched.problematicAreas &&
                formik.errors.problematicAreas}
            </FormErrorMessage>
          </FormControl>
          <UploadImage imgUpload={imgUpload} multiple />
          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={images} strategy={rectSortingStrategy}>
              {images.map((img, idx) => (
                <RenderImageVisitsContainer
                  key={img.url}
                  id={img.url}
                  image={img}
                  getNewArray={(payload) => handleChangeImages(payload, idx)}
                  getNewArrayDelete={() => handleRemoveImage(idx)}
                />
              ))}
            </SortableContext>
          </DndContext>

          <TextAreaInput
            name="generalObservations"
            label="Observaciones Generales"
            placeholder="Observaciones generales"
            value={formik.values.generalObservations}
            onChange={formik.handleChange}
            errors={
              formik.touched.generalObservations &&
              Boolean(formik.errors.generalObservations)
            }
            errorMsg={formik.errors.generalObservations}
          />
          <Stack spacing={10} align={'center'}>
            <Button
              align="center"
              isLoading={loadingUpdate}
              onClick={handleClickConfirm}
              bg="#FFFFFF"
              color="#59BABA"
              border="1px"
              borderColor="#59BABA"
              disabled={loadingUpdate}
            >
              Guardar documento
            </Button>
            {openConfirm && (
              <ConfirmModal
                isOpen={openConfirm}
                onClose={closeConfirm}
                route={`/visitas/${report.id_visit}`}
                confirm={confirm}
                setConfirm={setConfirm}
                handleSubmit={formik.handleSubmit}
                loading={loadingUpdate}
              />
            )}
          </Stack>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default VisitReport
