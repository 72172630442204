import {
  Box,
  SimpleGrid,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Image,
  Text,
  Textarea,
  Stack
} from '@chakra-ui/react'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DeleteIcon } from '../../components/Shared/Icons'
import _ from 'lodash'

const ImageItem = ({
  img,
  haveComments,
  handleChangeImages,
  handleDeleteImages,
  idx
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef
  } = useSortable({ id: img.url })

  const style = {
    transform: CSS.Transform.toString(transform),
    width: '100%',
    transition
  }

  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    let filename = img.substr(index)
    const ext = filename.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1] || '..'
    if (filename.length > 22) {
      filename = `${filename.slice(0, 20)}..`
    }

    return `${filename}${ext}`
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <SimpleGrid
        columns={2}
        w="100%"
        gap={2}
        key={img.url}
        borderRadius={'lg'}
        boxShadow={'md'}
        p={2}
      >
        <Flex gap={2} justify="space-between" p={2}>
          <Stack>
            <button ref={setActivatorNodeRef} {...listeners}>
              Mover
            </button>
            <Image
              src={img.url}
              alt="Imagen"
              height={200}
              w={200}
              onClick={() => window.open(img.url, '_blank')}
              cursor="pointer"
            />
            <Text>{getFilename(img.url)}</Text>
          </Stack>
          <IconButton
            icon={<Icon fontSize="22" as={DeleteIcon} />}
            onClick={() => handleDeleteImages(idx)}
          />
        </Flex>
        <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'}>
          {haveComments && (
            <Flex gap={2} justify="space-between" p={2}>
              <FormControl id="observation">
                <FormLabel>Observaciones</FormLabel>
                <Textarea
                  name="observation"
                  value={img.observation}
                  onChange={(event) => handleChangeImages(event, idx)}
                  placeholder="Observaciones"
                />
              </FormControl>
            </Flex>
          )}
        </Box>
      </SimpleGrid>
    </div>
  )
}

const RenderImageContainer = ({
  images,
  haveComments = true,
  getNewArray = () => {},
  getNewArrayDelete = () => {}
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (!over) {
      return
    }

    if (active.id !== over.id) {
      const oldIndex = images.findIndex((img) => img.url === active.id)
      const newIndex = images.findIndex((img) => img.url === over.id)
      getNewArray(arrayMove(images, oldIndex, newIndex))
    }
  }

  //Actualiza los valores de la lista de imagenes
  const handleChangeImages = (event, position) => {
    const { name, value } = event.target
    const newArray = _.cloneDeep(images)
    newArray[position] = {
      ...newArray[position],
      [name]: value
    }
    getNewArray(newArray)
  }

  const handleDeleteImages = (position) => {
    const newArray = _.cloneDeep(images)
    newArray.splice(position, 1)
    getNewArrayDelete(newArray)
  }

  return (
    <Flex
      direction="column"
      justify="center"
      alignItems="center"
      gap={2}
      w="100%"
    >
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={images} strategy={rectSortingStrategy}>
          {images.map((img, idx) => (
            <ImageItem
              key={idx}
              img={img}
              haveComments={haveComments}
              handleChangeImages={handleChangeImages}
              handleDeleteImages={handleDeleteImages}
              idx={idx}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Flex>
  )
}

export default RenderImageContainer
