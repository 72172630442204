import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  Textarea,
  Switch,
  SimpleGrid,
  Stack
} from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DeleteIcon } from './Icons'

const RenderImageVisitsContainer = ({
  image,
  haveComments = true,
  getNewArray = () => {},
  getNewArrayDelete = () => {}
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef
  } = useSortable({ id: image.url })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  //Obtener nombre de imagen
  const getFilename = (img) => {
    const index = img.lastIndexOf('/') + 1
    let filename = img.substr(index)
    const ext = filename.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[1] || '..'
    if (filename.length > 22) {
      filename = `${filename.slice(0, 20)}..`
    }

    return `${filename}${ext}`
  }

  //Actualiza los valores de la lista de imagenes
  const handleChangeImages = (event) => {
    const { name, value } = event.target
    getNewArray({ name, value })
  }

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'} p={2}>
        <Flex
          direction="column"
          justify="center"
          alignItems="center"
          gap={2}
          w="100%"
        >
          <SimpleGrid columns={2} w="100%" gap={2} key={image.url}>
            <Flex gap={2} justify="space-between" p={2}>
              <Stack>
                <button ref={setActivatorNodeRef} {...listeners}>
                  Mover
                </button>
                <Image
                  src={image.url}
                  alt="Imagen"
                  height={200}
                  w={200}
                  onClick={() => window.open(image.url, '_blank')}
                  cursor="pointer"
                />
                <Text>{getFilename(image.url)}</Text>
              </Stack>
              <IconButton
                icon={<Icon fontSize="22" as={DeleteIcon} />}
                onClick={getNewArrayDelete}
              />
            </Flex>
            <Box w="100%" pb={5} borderRadius={'lg'} boxShadow={'md'}>
              <Flex gap={2} justify="space-between" p={2}>
                <HStack justify={'space-between'} w="100%">
                  <Text fontSize="14px" fontWeight="bold" color="#36355F">
                    ¿Permitir agregar plan de acción?
                  </Text>
                  <Switch
                    size="md"
                    isChecked={image.hasActionPlan}
                    onChange={(e) =>
                      handleChangeImages({
                        target: {
                          name: 'hasActionPlan',
                          value: e.target.checked
                        }
                      })
                    }
                    colorScheme={'cyan'}
                    name="monitored"
                  />
                </HStack>
              </Flex>
              {image.hasActionPlan && (
                <Flex gap={2} justify="space-between" p={2}>
                  <FormControl id="action_plan">
                    <FormLabel>Plan de acción</FormLabel>
                    <Textarea
                      name="action_plan"
                      value={image.action_plan}
                      onChange={handleChangeImages}
                      placeholder="Plan de acción"
                    />
                  </FormControl>
                </Flex>
              )}
              {haveComments && (
                <Flex gap={2} justify="space-between" p={2}>
                  <FormControl id="observation">
                    <FormLabel>Observaciones</FormLabel>
                    <Textarea
                      name="observation"
                      value={image.observation}
                      onChange={handleChangeImages}
                      placeholder="Observaciones"
                    />
                  </FormControl>
                </Flex>
              )}
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>
    </div>
  )
}

export default RenderImageVisitsContainer
